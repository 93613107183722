<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";

/**
 * Starter component
 */
export default {
  page: {
    title: "Divisions",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Divisions",
      name : "",
      id : "",
      breadcumbs: [
        {
          text: "Divisions",
          active: true
        }
      ]
    };
  },
  computed: {
    items() { return this.$store.getters['division/items'] },
    item() { return this.$store.getters['division/item'] },
    state() { return this.$store.getters['division/state'] },
    loading() { return this.$store.getters['division/loading'] },
    message() { return this.$store.getters['division/message'] },
    error() { return this.$store.getters['division/error'] },
  },
  mounted(){
    this.$store.dispatch("division/get")
    //   this.getSoals();
  },
  methods:{
    reset(){
        this.name = "";
        this.id = "";
    },
    showCreate(){
          this.reset();
          this.$store.commit("division/SET_STATE","CREATE")
    },
    showList(){
        this.reset();
        this.$store.commit("division/SET_STATE","LIST")
    },
    save(){
      var param = {
          name : this.name
      }
      this.$store.dispatch("division/create",param)
      this.reset();
    },
    edit(data){
        this.reset();
        this.name = data.name
        this.id = data.id
        this.$store.commit("division/SET_STATE","EDIT")
    },
    update(){
      var param = {
          name : this.name,
          id : this.id
      }
      this.$store.dispatch("division/edit",param)
      this.reset();
    },
    confirm(id) {
        this.reset();
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Yang Di hapus Tidak dapat di kembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Ya, Hapus !"
      }).then(result => {
        if (result.value) {
          this.$store.dispatch("division/delete",id);
          Swal.fire("Deleted!", "Berhasil Data Akan Segera Terhapus.", "success");
        }else{
          Swal.fire("Cancel!", "Data Tidak Terhapus", "error");
        }
      });
    },
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="breadcumbs" />
      <div class="row">
        <div class="col-md-12">
            <b-alert show dismissible fade v-show="message != ''">{{message}}</b-alert>
        </div>
        <!-- start list -->
        <div class="col-md-12" v-if="state == 'LIST'">
          <button class="btn btn-pill btn-primary mb-2" @click="showCreate()"> <i class="bx bx-user-plus"></i> Tambah Data Division</button>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Data Division</h4>
              <div class="table-responsive">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="list in items" :key="list.id">
                      <td>{{list.name}}</td>
                      <td>
                          <button class="btn btn-danger btn-sm  mx-1" @click="confirm(list.id)" v-b-tooltip.hover title="Delete" ><i class="bx bx-trash"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- end list -->
        <!-- start create -->
          <div class="col-md-12" v-if="state == 'CREATE'">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Create New Division</h4>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <!-- <label for="name" class="control-label">Division Name <span class="text-danger">*</span></label> -->
                        <input type="text" class="form-control" v-model="name" id="name" placeholder="Division Name *">
                      </div>
                    </div>
                  </div>
              </div>
              <div class="card-footer">
                <button class="btn btn-danger btn-sm mx-1" @click="showList()"  v-b-tooltip.hover title="Cancel"><i class="bx bx-left-arrow-alt"></i> Cancel</button>
                <button class="btn btn-info btn-sm  mx-1" @click="save()" :disabled="loading" v-b-tooltip.hover title="Save" ><i v-if="loading" class="bx bx-loader-circle"></i> <i v-else class="bx bxs-cloud-upload"></i> Save</button>
              </div>
            </div>
          </div>
        <!-- end create -->
      </div>
  </Layout>
</template>